<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link :to="{name: 'Add Koin Nusantara Product'}" class="btn btn-primary"><i class="fa fa-plus"></i> Create Product</router-link>&nbsp;
          <button v-if="$can('export')" class="btn btn-success" type="button" @click="exportTable">Export</button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card :no-body="true">
          <b-card-body class="p-0 clearfix">
              <i class="fa fa-money bg-success p-4 font-2xl mr-3 float-left"></i>
              <div class="h5 text-primary mb-0 p-2">IDR : {{ this.buying_rate }}/1 g</div>
              <div class="text-muted text-uppercase font-weight-bold font-xs">Harga beli Emas saat ini</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <template slot="sku-slot" slot-scope="prop">
          <span>{{ prop.rowData.sku }}</span>
        </template>

        <template slot="unit" slot-scope="prop">
          <span>{{prop.rowData.unit === null ? '-' : prop.rowData.unit}}</span>
        </template>

        <template slot="minting_fee-slot" slot-scope="prop">
          <span>{{ prop.rowData.minting_fee }}</span>
        </template>

        <template slot="brand-slot" slot-scope="prop">
          <span>{{ prop.rowData.source === null ? '-' : prop.rowData.source }}</span>
        </template>

        <template slot="name_product-slot" slot-scope="prop">
          <span>{{ prop.rowData.name_product }}</span>
        </template>
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button  class="btn btn-info" @click="onAction('edit-item', prop.rowData)">Edit</button>&nbsp;
            <button  class="btn btn-danger" @click="onAction('delete-item', prop.rowData)"><i class="fa fa-trash-o"></i></button>
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import Pusher from 'pusher-js' // import Pusher
  import moment from 'moment'

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        goldRate: '',
        buying_rate: '',
        isLoading: false,
        filterText: '',
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `product-sku`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'amount',
            sortField: 'amount',
            direction: 'asc'
          },{
            field: 'name',
            sortField: 'name',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'sku-slot',
            title: 'Product ID'
          },
          {
            name: 'unit',
            title: 'Jumlah Stok'
          },
          {
            name: 'name_product-slot',
            title: 'Nama Produk'
          },
          {
            name: 'brand-slot',
            title: 'Brand'
          },
          {
            name: 'name',
            sortField: 'name',
            title: 'Tipe Produk'
          },
          {
            name: 'amount',
            sortField: 'amount',
            title: 'Gram Value'
          },
          {
            name: 'minting_fee-slot',
            title: 'Minting Fee'
          },
          {
            name: 'actions-slot',
            title: 'Actions',
            titleClass: 'center aligned',
            dataClass: 'center aligned'
          }
        ]
      }
    },
    created () {
      this.$http
      .get(`pricing`)
      .then((response) => {
        this.pricing = response.data.meta.data.price;
        this.buying_rate = 'Rp ' + response.data.meta.data.gold_rate.buying_rate;
        this.goldRate = moment(this.pricing.gold_rate.created_at).format('DD/MM/YYYY HH:mm')
      })
      .catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      });
      this.subscribe()
    },
    methods: {
      gramFormat(value) {
        return value + ' Gram'
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        this.$http.get(`product-sku-list`)
        .then((result) => {
          this.isLoading = false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      subscribe () {
        let that = this;
        let pusher = new Pusher('52e99bd2c3c42e577e13', { cluster: 'ap1', forceTLS: true })
        pusher.subscribe('gold-rate')
        pusher.bind('gold-rate-event', data => {
          let buying = data.buying_rate
          let price = 0
          price= buying * 1000
          that.buying_rate = price.toFixed()
          that.goldRate = moment(data.created_at).format('DD/MM/YYYY HH:mm')
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      doDateFilter(value){
          this.startDate = value.startDate;
          this.endDate = value.endDate;
          this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
          this.start_from = this.startDate;
          this.end_to = this.endDate;
          this.moreParams = {
              'start_from': this.start_from,
              'end_to': this.end_to
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        if (action == 'edit-item') {
          this.$router.push({name: 'Edit Koin Nusantara Product', params: { id: data.from, product: data.sku_detail}})
        }else{
          this.$swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this Product!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {

                this.$http.get(`delete-product-sku/?list=` + data.from + `&product=` + data.sku_detail)
                .then(() => {
                  this.$swal.fire(
                    'Deleted!',
                    'Product has been deleted.',
                    'success'
                  ).then(() => {
                    location.reload();
                  })
                }).catch((error) => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                'Cancelled',
                'Product was successfully canceled for deletion!',
                'error'
              )
            }
          })
        }
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
